<template>
  <a-card :bordered="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }">
        <a-row :gutter="2">
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="商品名称">
              <a-input v-model.trim="searchData.name" placeholder="请输入商品名称" :max-length="50" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="品牌">
              <a-select v-model="searchData.brandId" placeholder="请选择商品品牌" allowClear>
                <a-select-option :value="item.id" v-for="(item, index) in proBrandList" :key="index">
                  {{ item.ch_name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="功能类型">
              <a-select v-model="searchData.typeId" placeholder="请选择功能类型" allowClear>
                <a-select-option :value="item.id" v-for="(item, index) in fyList" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item :labelCol="{ span: 0 }" :wrapperCol="{ span: 22, offset: 1 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button v-if="checkInfo" type="primary" @click="toHandler('checkInfo')">查看</a-button>
        <a-button type="primary" @click="toHandler('add')">新增</a-button>
        <a-button v-if="update" type="primary" @click="toHandler('update')">修改</a-button>
        <a-button
          v-if="selectedRows.length > 0 && selectedRows[0].flagUp == 2"
          type="default"
          @click="onStatusChange('up')"
          icon="check-circle"
          >上架</a-button
        >
        <a-button
          v-if="selectedRows.length > 0 && selectedRows[0].flagUp == 1"
          type="default"
          @click="onStatusChange('down')"
          icon="stop"
          >下架</a-button
        >
        <a-button v-if="update" type="default" @click="toHandler('del')">删除</a-button>
        <!-- <a-button v-if="updateOne" type="primary" @click="toHandler('updateOne')">修改详情介绍</a-button> -->
        <!-- <a-button v-if="updateTwo" type="primary" @click="toHandler('updateTwo')">修改样本资料</a-button>
        <a-button v-if="updateThree" type="primary" @click="toHandler('updateThree')">修改检测报告</a-button>
        <a-button v-if="updateFour" type="primary" @click="toHandler('updateFour')">修改产品手册</a-button> -->
        <!-- <a-button v-if="updateFive" type="primary" @click="toHandler('updateFive')">上传素材</a-button> -->
      </div>
      <!-- 数据表格 -->
      <a-table
        :rowClassName="rowClassName"
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :customRow="changeTableRow"
      >
        <span slot="fyName" slot-scope="item, row">
          <!-- {{item == 1 ? '上架' : item == 2 ? '下架' : '强制下架'}} -->
          <a-tag v-for="item in row.fncTypeList" :key="item.index">{{ item.typeName }}</a-tag>
        </span>
        <span slot="flagUp" slot-scope="item">
          <!-- {{item == 1 ? '上架' : item == 2 ? '下架' : '强制下架'}} -->
          <a-tag v-if="item == 1" color="#67c23a">上架</a-tag>
          <a-tag v-if="item == 2" color="#909399">下架</a-tag>
          <a-tag v-if="item == 3" color="#909399">强制下架</a-tag>
        </span>
        <span slot="imgUrl" slot-scope="item">
          <ZoomMedia style="height: 20px" :value="item"></ZoomMedia>
        </span>
      </a-table>
      <!-- 编辑表单 -->
      <ProductCenterEditModel ref="ProductCenterEditModel" @reload="getData"></ProductCenterEditModel>
    </div>
  </a-card>
</template>

<script>
import { columns } from './components/colums.js'
import ProductCenterEditModel from './components/ProductCenterEditModel.vue'
import { listAllGoods, delProductGoods } from './api/productCenterApi.js'
import { checkPermission } from '@/utils/permissions'
const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'productCenter',
  components: {
    ProductCenterEditModel,
  },
  data() {
    return {
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      // page: Object.assign({}, pageSource),
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      options: [],
      categoryList: [],
      categoryValue: [],
      checkInfo: checkPermission('xcx:productCenter:checkInfo'),
      update: checkPermission('xcx:productCenter:update'),
      categorySelectData: [],
      proBrandList: [],
      visible: false,
      confirmLoading: false,
      fyList: [],
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    onCategoryChange(value) {
      console.log(value)
      this.categorySelectData = value
      var reg = new RegExp(',', 'g')
      this.searchData.cId = value
        .map((i) => {
          return i
        })
        .toString()
        .replace(reg, '_')
    },
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },
    // 审核
    onAudit(name) {
      const _this = this
      if (_this.selectedRows.length === 0) {
        _this.$notification.info({
          message: '请选择一条记录',
        })
        return
      }
      let query = {}
      if (name == 'sbm_audit') {
        query = {
          id: _this.selectedRows[0].id,
          flowStatus: 2,
        }
      } else if (name == 'audit') {
        query = {
          id: _this.selectedRows[0].id,
          flowStatus: 3,
        }
      } else if (name == 'refuse') {
        const data = {
          id: _this.selectedRowKeys[0],
          num: _this.realQuantity,
        }
        _this.confirmLoading = true
        _this.axios.get('/api/product/system/goods/updateRepository', { params: data }).then((res) => {
          if (res.code == 200) {
            _this.$notification.success({ message: res.message })
            _this.visible = false
            _this.selectedRowKeys = []
            _this.selectedRows = []
            _this.getData()
          } else {
            _this.$notification.error({ message: res.message })
          }
          _this.confirmLoading = false
        })
      }
      //   _this.axios.post('/api/product/system/info/review', query).then((res) => {
      //     if (res.code == 200) {
      //       _this.$notification.success({ message: res.message })
      //     } else {
      //       _this.$notification.error({ message: res.message })
      //     }
      //   })
    },
    // 查看
    onCheck(item) {
      const _this = this
      _this.$refs.ProductCenterEditModel.setRowData(item, 'check')
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.selectedRowKeys =[]
      this.selectedRows = []
      this.tableLoading = true
      listAllGoods({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
            this.selectedRowKeys = []
            this.selectedRows = []
          }
        })
        .finally(() => (this.tableLoading = false))
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page.current = 1;
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.categoryValue = []
      this.searchData = {}
      this.categorySelectData = []
      this.page.current = 1;
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    onSync() {
      this.tableLoading = true
      this.axios.post('/api/product/system/goods/synchronizedInventor').then((res) => {
        if (res.code == 200) {
          this.getData()
          this.$notification.success({ message: res.message })
          this.tableLoading = false
        }
      })
    },
    // 编辑
    handleEdit(row) {
      const _this = this
      // _this.$refs.ProductCenterEditModel.setRowData(row, 'edit')
    },
    // 删除
    delByIds(id) {
      const _this = this
      _this.$confirm({
        title: '删除警告',
        content: '确认要删除此条记录吗?',
        okText: '确认',
        cancelText: '取消',
        async onOk() {
          // const res = await delProductGoods(_this.selectedRows[0].id)
          // if (res.code === 200) {
          //   _this.$notification.success({ message: res.message })
          //   _this.getData()
          // } else {
          //   _this.$notification.error({ message: res.message })
          // }
        },
        onCancel() {},
      })
    },
    toHandler(name) {
      const _this = this

      if (name == 'add') {
        _this.$refs.ProductCenterEditModel.setRowData({}, 'add')
        return
      }

      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      if (name === 'update') {
        _this.$refs.ProductCenterEditModel.setRowData(_this.selectedRows[0].id, 'update')
        return
      }
      switch (name) {
        case 'checkInfo':
          _this.$refs.ProductCenterEditModel.setRowData(_this.selectedRows[0].id, 'checkInfo')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delProductGoods(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
      }
    },

    // 修改是否上架
    onStatusChange(name) {
      const that = this
      let num = {}
      if (that.selectedRows.length === 0) {
        that.$notification.info({
          message: '请选择一条记录',
        })
        return
      }
      if (name == 'up') {
        num = {
          id: that.selectedRows[0].id,
          flagUp: 1,
        }
      } else if (name == 'down') {
        num = {
          id: that.selectedRows[0].id,
          flagUp: 2,
        }
      }
      that.axios.post('/api/product/xcx/xcxProduct/editFlagUpStatus', num).then((res) => {
        that.$notification.success({ message: res.message })
        that.getData()
      })
    },
  },
  created() {
    this.getData()
    this.axios.get('/api/product/category/categoryList?flagEnable=true').then((res) => {
      this.categoryList = res.body[0] ? res.body[0].children : []
    })
    this.axios.get('/api/product/system/brand/All/list').then((res) => {
      this.proBrandList = res.body
    })
    this.axios.get('/api/product/xcx/xcxProductDetails/function/type').then((res) => {
      this.fyList = res.body
    })
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
.footer-btns {
  text-align: right;
  border-top: 1px solid #e8e8e8;
  padding-top: 20px;
  margin-top: 20px;
}
</style>
