<template>
  <a-modal
    class="product-form"
    :title="handle == 'add' ? '新增' : handle == 'edit' ? '编辑' : '查看'"
    style="top: 8px"
    width="70%"
    v-model="visible"
    :footer="null"
    :maskClosable="false"
  >
    <a-form-model
      ref="form"
      :model="rowData"
      labelAlign="right"
      :rules="formRule"
      layout="horizontal"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
    >
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="商品名称" prop="name">
            <a-input v-model="rowData.name" placeholder="请输入"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="商品品牌" prop="brandId" class="required_fields" :autoLink="false" ref="test">
            <a-select placeholder="请选择" v-model="rowData.brandId">
              <a-select-option :value="item.id" v-for="(item, index) in brandList" :key="index">
                {{ item.ch_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="功能类型" prop="fncTypeIdList">
            <a-select
              :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
              placeholder="请选择"
              mode="multiple"
              v-model="rowData.fncTypeIdList"
            >
              <a-select-option :value="item.id" v-for="(item, index) in functionType" :key="index">{{
                item.name
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>

        <a-col :md="12">
          <a-form-model-item label="型号" prop="model">
            <a-input
              :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
              v-model="rowData.model"
              placeholder="请输入"
            ></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="系列" prop="series">
            <a-input
              :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
              v-model="rowData.series"
              placeholder="请输入"
            ></a-input>
          </a-form-model-item>
        </a-col>

        <a-col :md="12">
          <a-form-model-item label="上架" prop="flagUp">
            <a-switch
              :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
              v-model="rowData.flagUp"
              checked-children="上架"
              un-checked-children="下架"
              default-checked
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="商品主图" class="buju required_fields" prop="imgUrl">
            <QiniuUpload
              :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
              type="image"
              :value.sync="rowData.imgUrl"
            ></QiniuUpload>
            <div class="upload-hint-text">支持扩展名：.jpg .png</div>
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="商品图片" class="buju">
            <QiniuUpload
              :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
              type="image"
              :value.sync="url"
              :size="5"
            ></QiniuUpload>
            <div class="upload-hint-text">支持扩展名：.jpg .png</div>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-tabs @change="callback">
        <a-tab-pane key="1" tab="详情介绍">
          <Tinymce :value.sync="rowData.detailedIntro" :tkey="tkey" ref="Tinymce"></Tinymce>
        </a-tab-pane>
        <a-tab-pane key="2" tab="样本资料">
          <!-- <Tinymce :value.sync="rowData.sampleData" :tkey="tkey" ref="Tinymce"></Tinymce> -->
          <div class="btn">
            <a-button type="primary" @click="onShowFiles('sample')">新增</a-button>
          </div>
          <a-table :data-source="sampleDataDocList" bordered v-if="sampleDataDocList.length > 0">
            <a-table-column title="文件名" data-index="name" align="center"></a-table-column>
<!--            <a-table-column title="文件链接" data-index="url" align="center"></a-table-column>-->
            <a-table-column title="文件链接" align="center">
              <template slot-scope="row">
                <a :href="row.url" target="_blank" class="buttonText">{{row.url}}</a>
              </template>
            </a-table-column>
            <a-table-column title="操作" data-index="" align="center">
              <template slot-scope="row">
                <a @click="onDelFiles(row)">删除</a>
              </template>
            </a-table-column>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="3" tab="检测报告">
          <!-- <Tinymce :value.sync="rowData.productData" :tkey="tkey" ref="Tinymce"></Tinymce> -->
          <div class="btn">
            <a-button type="primary" @click="onShowFiles('examining_report')">新增</a-button>
          </div>
          <a-table :data-source="testReportDocList" bordered v-if="testReportDocList.length > 0">
            <a-table-column title="文件名" data-index="name" align="center"></a-table-column>
<!--            <a-table-column title="文件链接" data-index="url" align="center"></a-table-column>-->
            <a-table-column title="文件链接" align="center">
              <template slot-scope="row">
                <a :href="row.url" target="_blank" class="buttonText">{{row.url}}</a>
              </template>
            </a-table-column>
            <a-table-column title="操作" data-index="" align="center">
              <template slot-scope="row">
                <a @click="onDelFiles(row)">删除</a>
              </template>
            </a-table-column>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="4" tab="投标专用">
          <div class="btn">
            <a-button type="primary" @click="onShowFiles('bid')">新增</a-button>
          </div>
          <a-table :data-source="biddingDocList" bordered v-if="biddingDocList.length > 0">
            <a-table-column title="文件类型" data-index="typeName" align="center"></a-table-column>
            <a-table-column title="文件名" data-index="name" align="center"></a-table-column>
<!--            <a-table-column title="文件链接" data-index="url" align="center"></a-table-column>-->
            <a-table-column title="文件链接" align="center">
              <template slot-scope="row">
                <a :href="row.url" target="_blank" class="buttonText">{{row.url}}</a>
              </template>
            </a-table-column>
            <a-table-column title="操作" data-index="" align="center">
              <template slot-scope="row">
                <a @click="onDelFiles(row)">删除</a>
              </template>
            </a-table-column>
          </a-table>

          <div class="btn" style="display: flex; align-items: center;">
            <span>下载密码：</span>
            <span><a-input-password v-model="rowData.checkPwd"></a-input-password></span>
          </div>
        </a-tab-pane>
      </a-tabs>
      <div class="footer-bts">
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="toSubmit">保存</a-button>
      </div>
    </a-form-model>
    <AddFilesPage ref="AddFilesPage" @getItem="getItem" @getExaminingList="getExaminingList" @getSampleList="getSampleList"></AddFilesPage>
  </a-modal>
</template>

<script>
import { selectByIdProductCenter, addOrEditProductCenter, editOrEditProductCenter } from '../api/productCenterApi.js'
import Tinymce from '../../../components/Editor/Tinymce'
import AddFilesPage from './AddFilesPage.vue'

export default {
  components: {
    Tinymce,
    AddFilesPage,
  },
  data() {
    return {
      rowData: {},
      visible: false,
      handle: 'add',
      contentByOne: '',
      contentByTwo: '',
      contentByThree: '',
      contentByFour: '',
      contentByFive: '',
      annexType: '',
      skuId: 0,
      footer: null,
      // 表单验证
      formRule: {
        name: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
        brandId: [{ required: true, message: '请选择品牌', trigger: 'change' }],
        fncTypeIdList: [{ required: true, message: '请选择功能类型', trigger: 'change' }],
        model: [{ required: true, message: '请输入型号', trigger: 'blur' }],
        series: [{ required: true, message: '请输入系列', trigger: 'blur' }],
        imgUrl: [{ required: true, message: '请上传图片', trigger: 'change' }],
        contentByOne: [{ required: true, message: '请输入详情介绍', trigger: 'blur' }],
        contentByTwo: [{ required: true, message: '请输入样本资料', trigger: 'blur' }],
      },
      tkey: 0,
      brandList: [],
      functionType: [],
      url: '',
      loading: false,
      biddingDocList: [],
      sampleDataDocList: [],
      testReportDocList: []
    }
  },
  methods: {
    /**
     * 获取行数据
     */
    setRowData(id, handle) {
      this.handle = handle
      this.visible = true
      this.rowData = {}
      this.biddingDocList = []
      this.sampleDataDocList = []
      this.testReportDocList = []
      this.url = ''
      this.skuId = id
      if (handle == 'update') {
        this.footer = undefined
      } else {
        this.footer = null
      }
      if (handle == 'update' || handle == 'checkInfo') {
        selectByIdProductCenter(id).then((res) => {
          this.rowData = res.body
          if(this.rowData.flagUp == 1){
            this.rowData.flagUp = true;
          }
          if(this.rowData.flagUp == 2){
            this.rowData.flagUp = false;
          }
          this.biddingDocList = res.body.biddingDocList
          this.sampleDataDocList = this.rowData.sampleDataDocList
          this.testReportDocList = this.rowData.testReportDocList
          this.url = res.body.pictureList
            ? res.body.pictureList
                .map((e) => {
                  return e.url
                })
                .toString()
            : ''
          // this.$forceUpdate()
        })
      }
    },

    callback(key) {
      // console.log(this.$refs)
      // this.$refs.Tinymce.onActivated()
      this.tkey++
    },

    handleCancel() {
      this.visible = false
    },

    onShowFiles(name) {
      let handle = name == 'sample' ? 'sample' : name == 'examining_report' ? 'examining_report' : 'bid'
      this.$refs.AddFilesPage.show(handle)
    },

    getItem(item) {
      this.biddingDocList.push(item)
    },

    getExaminingList(item) {
      this.testReportDocList.push(item)
    },

    getSampleList(item) {
      this.sampleDataDocList.push(item)
    },

    onDelFiles(row) {
      this.biddingDocList.splice(
        this.biddingDocList.findIndex((e) => {
          return e.typeId == row.typeId
        }),
        1
      )
    },

    /**
     * 表单提交
     */
    toSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }


        if(this.rowData.flagUp){
          this.rowData.flagUp = 1;
        }
        if(!this.rowData.flagUp){
          this.rowData.flagUp = 2;
        }

        this.rowData.biddingDocList = this.biddingDocList
        this.rowData.sampleDataDocList = this.sampleDataDocList
        this.rowData.testReportDocList = this.testReportDocList

        this.rowData.pictureList = this.url.split(',').map((item) => {
          return {
            url: item,
          }
        })
        console.log(this.handle)

        if (this.handle == 'add') {
          addOrEditProductCenter(this.rowData).then((res) => {
            if (res.code === 200) {
              this.$notification.success({ message: res.message })
              this.$emit('reload')
              this.visible = false
            } else {
              this.$notification.error({ message: res.message })
            }
          })
        }

        if (this.handle == 'update') {
          editOrEditProductCenter(this.rowData).then((res) => {
            if (res.code === 200) {
              this.$notification.success({ message: res.message })
              this.$emit('reload')
              this.visible = false
            } else {
              this.$notification.error({ message: res.message })
            }
          })
        }
      })
    },
  },
  created() {
    // 获取品牌列表
    this.axios.get('/api/product/system/brand//All/list').then((res) => {
      this.brandList = res.body
    })

    // 获取功能类型列表
    this.axios.get('/api/product/product/productFunctionType/list?pageSize=99999').then((res) => {
      this.functionType = res.body.records
    })
  },
}
</script>

<style lang="less" scoped>
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9;
}
.btn {
  padding-left: 50px;
}
</style>
