/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-07-19 14:40:07
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-21 20:52:33
 */
export const columns = [
  {
    title: '商品图片',
    dataIndex: 'imgUrl',
    key: 'imgUrl',
    /*width:150,*/
    align:'center',
    scopedSlots: {customRender: 'imgUrl'}
},
{
    title: '商品名称',
    dataIndex: 'name',
    key: 'name',
    /*width:150,*/
    align:'center'
},
{
    title: '品牌',
    dataIndex: 'brandName',
    key: 'brandName',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '功能类型',
    dataIndex: 'fyName',
    key: 'fyName',
    /*width:150,*/
    align:'center',
    ellipsis:true,
   scopedSlots: { customRender: 'fyName'}
},
{
    title: '型号',
    dataIndex: 'model',
    key: 'model',
    /*width:150,*/
    align:'center'
},
{
    title: '系列',
    dataIndex: 'series',
    key: 'series',
    /*width:150,*/
    align:'center'
},
  {
    title: '上架状态',
    dataIndex: 'flagUp',
    key: 'flagUp',
    /*width:150,*/
    align:'center',
    scopedSlots: { customRender: 'flagUp'}
  },
  {
    title: '创建人',
    dataIndex: 'createUser',
    key: 'createUser',
    /*width:150,*/
    align:'center'
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    /*width:150,*/
    align:'center'
  },

]
